import loginBackground from '/assets/login/login-left-background.png';
import { Box, Link, SxProps, Typography } from '@mui/material';
import { FC, FunctionComponent } from 'react';
import LogoWithSantaHat from '../components/santa/LogoWithSantaHat';

interface PublicLayoutPageProps {
  component: FunctionComponent;
}

const PublicLayoutPage: FC<PublicLayoutPageProps> = ({ component: Component }) => {
  const loginLayoutSx: SxProps = {
    minHeight: '100vh',
    maxHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    flexDirection: ['column-reverse', 'row'],
    backgroundColor: 'white',
  };

  const leftBoxSx: SxProps = {
    backgroundImage: `url("${loginBackground}")`,
    flexBasis: [null, '500px'],
    flexGrow: 0,
    flexShrink: 1,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    alignSelf: [null, 'stretch'],
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: ['64px 24px', '64px 45px'],
    color: 'white',
    gap: '24px',
  };

  const appIntroductionTextSx: SxProps = {
    fontWeight: 400,
    fontSize: '20px',
    textAlign: 'left',
    lineHeight: '28px',
  };

  const rightBoxSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexBasis: ['95vh', '85%'],
    minHeight: ['unset', '92vh'],
    flex: 1,
    flexShrink: 10,
    gap: '14px',
    padding: '40px',
  };

  const logoBoxSx: SxProps = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const logoAppNameSx: SxProps = {
    marginLeft: '0.875rem',
    fontWeight: 500,
    fontSize: '32px',
  };

  const connectionBoxSx: SxProps = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
  };

  const footerTextSx: SxProps = {
    fontWeight: 400,
    fontSize: '16px',
    color: '#8F8F8F',
  };

  return (
    <Box sx={loginLayoutSx}>
      <Box sx={leftBoxSx}>
        <Typography sx={appIntroductionTextSx}>Bid Adjustments: Introducing J+Bidding</Typography>
      </Box>
      <Box sx={rightBoxSx}>
        <Box sx={logoBoxSx}>
          <LogoWithSantaHat logoSrc="/assets/jplus-media-logo.png" altText="j+ bidding" />
          <Typography sx={logoAppNameSx} variant="h6">
            Bidding
          </Typography>
        </Box>
        <Box sx={connectionBoxSx}>
          <Component />
        </Box>
        <Box>
          <Typography sx={footerTextSx} variant="h6">
            Visit{' '}
            <Link
              href="https://www.jellyfish.com/en-us/jplus/"
              target="_blank"
              rel="noopener"
              underline="hover"
              sx={footerTextSx}
            >
              jplus.io
            </Link>{' '}
            for more products
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicLayoutPage;
