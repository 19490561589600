import React, { useState } from 'react';
import './LogoWithSantaHat.css';

type LogoWithSantaHatProps = {
  logoSrc: string;
  altText: string;
};

const LogoWithSantaHat: React.FC<LogoWithSantaHatProps> = ({ logoSrc, altText }) => {
  const [showHat] = useState(false);

  return (
    <div className="logo-container">
      {/* Logo */}
      <div className="logo-wrapper">
        <img src={logoSrc} alt={altText} className="logo" />
        {showHat && <img src="/assets/santa-hat.png" alt="Santa hat" className="santa-hat" />}
      </div>
    </div>
  );
};

export default LogoWithSantaHat;
