import { create } from 'zustand';
import { container } from '../../generic/ioc/container.ts';
import INJECTION_TOKENS from '../../generic/ioc/injection-tokens.ts';
import { Advertiser } from '../model/advertiser.ts';
import { Client } from '../model/client';
import { FloodlightsPerAdvertiser } from '../model/floodlight.ts';
import { InsertionOrdersPerAdvertiser } from '../model/insertion-order.ts';
import { Dv360AdvertiserPort } from '../port/dv360-advertiser.port.ts';
import { Dv360ClientPort } from '../port/dv360-client.port.ts';
import { Dv360FloodlightPort } from '../port/dv360-floodlight.port.ts';
import { Dv360IoPort } from '../port/dv360-io.port.ts';
import { buildKeyPerClientPartner } from './dv360-store.helper.ts';

type SnackBarSeverity = 'success' | 'error' | undefined;

type SnackbarProps = {
  messages: string[];
  severity: SnackBarSeverity;
  visible: boolean;
};

type AdvertisersPerPartner = {
  [key: string]: Advertiser[];
};

type AdvertiserFloodlightsPerCLientPerPartner = {
  [clientIdPartnerId: string]: FloodlightsPerAdvertiser;
};

type AdvertiserIoPerCLientPerPartner = {
  [clientIdPartnerId: string]: InsertionOrdersPerAdvertiser;
};

export type DV360StoreState = {
  isLoading: boolean;
  snackBarProps: SnackbarProps;
  clients: Client[];
  isAdvertisersLoading: boolean;
  advertisersPerPartner: AdvertisersPerPartner;
  floodlightsPerAdvertiser: AdvertiserFloodlightsPerCLientPerPartner;
  isFloodlightLoading: boolean;
  iosPerAdvertiser: AdvertiserIoPerCLientPerPartner;
  isIoLoading: boolean;
};

export type DV360StoreActions = {
  reset: () => void;
  setSnackBarProps: (snackBarProps: SnackbarProps) => void;
  setClients: () => Promise<void>;
  setAdvertisers: (clientId: string, partnerId: string) => Promise<void>;
  setFloodlights: (clientId: string, partnerId: string) => Promise<void>;
  setInsertionOrders: (clientId: string, partnerId: string) => Promise<void>;
};

const clientRepository = container.get<Dv360ClientPort>(INJECTION_TOKENS.DV360_CLIENT_REPOSITORY);
const advertiserRepository = container.get<Dv360AdvertiserPort>(
  INJECTION_TOKENS.DV360_ADVERTISER_REPOSITORY
);
const floodlightRepository = container.get<Dv360FloodlightPort>(
  INJECTION_TOKENS.DV360_FLOODLIGHT_REPOSITORY
);
const ioRepository = container.get<Dv360IoPort>(INJECTION_TOKENS.DV360_IO_REPOSITORY);

const initialState: DV360StoreState = {
  isLoading: false,
  snackBarProps: {
    messages: [],
    severity: undefined,
    visible: false,
  },
  clients: [],
  isAdvertisersLoading: false,
  advertisersPerPartner: {},
  floodlightsPerAdvertiser: {},
  isFloodlightLoading: false,
  iosPerAdvertiser: {},
  isIoLoading: false,
};

export const useDV360Store = create<DV360StoreState & DV360StoreActions>((set, get) => ({
  ...initialState,
  reset: () => {
    set(initialState);
  },
  setSnackBarProps: (snackBarProps) => {
    set({ snackBarProps });
  },
  setClients: async () => {
    set({ isLoading: true });
    if (get().clients.length) {
      set({ isLoading: false });
      return;
    }
    try {
      const clients = await clientRepository.getClients();
      const clientsPartnersWithClientId = clients
        .map((client) => ({
          ...client,
          partners: client.partners.map((partner) => ({
            ...partner,
            clientId: client.id,
          })),
        }))
        .sort((a, b) => -(b?.name ?? '').localeCompare(a?.name || ''));
      set({ clients: clientsPartnersWithClientId });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error fetching clients'],
          severity: 'error',
          visible: true,
        },
      });
    } finally {
      set({ isLoading: false });
    }
  },
  setAdvertisers: async (clientId, partnerId) => {
    const currentAdvertisersByPartner = get().advertisersPerPartner;
    const doDataExist = !!currentAdvertisersByPartner[partnerId];
    const isLoading = !doDataExist;
    set({ isAdvertisersLoading: isLoading });
    if (doDataExist) {
      return;
    }
    try {
      const currentAdvertisersByPartner = get().advertisersPerPartner;
      const advertisers = await advertiserRepository.getAdvertisers(clientId, partnerId);
      const advertisersWithClientId = advertisers.map((advertiser) => ({
        ...advertiser,
        clientId,
      }));
      const sortedAdvertisers = advertisersWithClientId.sort(
        (a, b) => -(b?.name ?? '').localeCompare(a?.name || '')
      );
      set({
        advertisersPerPartner: { ...currentAdvertisersByPartner, [partnerId]: sortedAdvertisers },
      });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error fetching advertisers'],
          severity: 'error',
          visible: true,
        },
      });
    } finally {
      set({ isAdvertisersLoading: false });
    }
  },
  setFloodlights: async (clientId, partnerId) => {
    const currentFloodlightsPerAdvertisers = get().floodlightsPerAdvertiser;
    const doDataExist =
      !!currentFloodlightsPerAdvertisers[buildKeyPerClientPartner(clientId, partnerId)];
    set({ isFloodlightLoading: !doDataExist });
    if (doDataExist) {
      return;
    }
    try {
      const floodlights = await floodlightRepository.getFloodlights(clientId, partnerId);
      set({
        floodlightsPerAdvertiser: {
          ...currentFloodlightsPerAdvertisers,
          [buildKeyPerClientPartner(clientId, partnerId)]: floodlights,
        },
      });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error fetching floodlights'],
          severity: 'error',
          visible: false,
        },
      });
    } finally {
      set({ isFloodlightLoading: false });
    }
  },
  setInsertionOrders: async (clientId, partnerId) => {
    const currentIoPerAdvertiser = get().iosPerAdvertiser;
    const doDataExist = !!currentIoPerAdvertiser[buildKeyPerClientPartner(clientId, partnerId)];
    set({ isFloodlightLoading: !doDataExist });
    if (doDataExist) {
      return;
    }
    try {
      const ios = await ioRepository.getIOs(clientId, partnerId);
      set({
        iosPerAdvertiser: {
          ...currentIoPerAdvertiser,
          [buildKeyPerClientPartner(clientId, partnerId)]: ios,
        },
      });
    } catch (e) {
      set({
        snackBarProps: {
          messages: ['Error fetching insertion orders'],
          severity: 'error',
          visible: false,
        },
      });
    } finally {
      set({ isFloodlightLoading: false });
    }
  },
}));
